import React, { FC } from 'react';

import { Stack, Text } from '@sparky';
import { DotIcon } from '@sparky/icons';
import { FeedbackColors } from '@sparky/types';

interface Props {
  color: Extract<keyof FeedbackColors, 'feedbackSuccess' | 'feedbackWarning' | 'feedbackError'>;
  children: string;
}

export const StatusIndicator: FC<Props> = ({ color, children }) => (
  <Stack direction="row" gap="1" alignY="center">
    <DotIcon color={color} />
    <Text size={{ initial: 'BodyS', md: 'BodyM' }} color="textLowEmphasis">
      {children}
    </Text>
  </Stack>
);
